
import React, {useEffect} from "react";
// import { useStaticQuery, graphql } from "gatsby";
import "../styles/main.scss";

/* eslint-disable */


const Base = ({ children, location }) => {

    //   const data = useStaticQuery(graphql`
//     query SiteTitleQuery {
//       site {
//         siteMetadata {
//           title
//         }
//       }
//     }
//   `);

  useEffect(() => {
    
    //Later

  }, [])

  return (
        <main className="links">{children}</main>
  );
};


export default Base;
